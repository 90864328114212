import cloneDeep from 'lodash.clonedeep';

/**
 *  函数防抖
 *  @param {Function} func  包装的函数
 *  @param {num} delay      延迟时间
 *  @param {boolean} immediate 第一次滚动会执行两次  开始滚动和结束滚动的时候
 *  @return {*}
 */

export function debounce(func, delay, immediate = false) {
	let timer,
		context = this;
	return (...args) => {
		if (immediate) {
			func.apply(context, args);
			immediate = false;
			return;
		}
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(context, args);
		}, delay);
	};
}

export function random(min, max) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function numberAppendSeparator(num) {
	return num.toString().replace(/\d+/, function (n) {
		return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	});
}

export function genPointInPolygen(basePoint, polygen) {
	const clonedPolygen = cloneDeep(polygen);
	let generatedPoint = genRandomPointByCenter(basePoint);
	while (!window.AMap.GeometryUtil.isPointInRing(generatedPoint, clonedPolygen)) {
		generatedPoint = genRandomPointByCenter(basePoint);
	}
	return generatedPoint.map((p) => Number(p.toFixed(6)));
}

export function genRandomPointByCenter(center, offset = 0.2) {
	const factor = Math.random() > 0.5 ? 1 : -1;
	return [center[0] + factor * Math.random() * offset, center[1] + factor * Math.random() * offset];
}

export const getHealthName = (cityName) => {
	const prefixes = ['市立', '区域', '中心', '社区', '综合', '专科', '公立', '私立'];
	const locations = ['东', '西', '南', '北', '中', '新', '旧', '和平', '长安', '丰富', '繁华'];
	const services = ['医疗', '保健', '诊疗', '康复', '急救', '预防', '全科', '妇产科', '儿科', '心理'];
	const suffixes = ['卫生院', '诊所', '医院'];

	const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
	const randomLocation = locations[Math.floor(Math.random() * locations.length)];
	const randomService = services[Math.floor(Math.random() * services.length)];
	const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)];

	const healthCenterName = cityName + randomPrefix + randomLocation + randomService + randomSuffix;

	return healthCenterName;
};
