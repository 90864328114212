import * as echarts from 'echarts';
import {random} from '@/utils'
export const CountAnimationDuration = 725;
import axios from 'axios'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import store from '../../store'

export const slider = (length, _num) => {
  const num = _num || 5
  return [
  {
    type: 'slider',
    realtime: true,
    start: 0,
    end: 90, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
    height: 8, //组件高度
    left: 2, //左边的距离
    right: 10, //右边的距离
    bottom: 0, //下边的距离
    show: length > (num+1), // 是否展示
    backgroundColor: '#051127',
    fillerColor: '#223A69', // 滚动条颜色
    borderColor: '#051127',
    handleStyle: {},
    borderRadius: 4, // 滚动条圆角
    handleSize: 0, //两边手柄尺寸
    showDetail: false, //拖拽时是否展示滚动条两侧的文字
    zoomLock: true, //是否只平移不缩放
    moveOnMouseMove: false, //鼠标移动能触发数据窗口平移

    //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
    startValue: 0, // 从头开始。
    endValue: num, // 最多六个
    minValueSpan: num, // 放大到最少几个
    maxValueSpan: num, //  缩小到最多几个
  },
  {
    type: 'inside', // 支持内部鼠标滚动平移
    start: 0,
    end: 35,
    zoomOnMouseWheel: false, // 关闭滚轮缩放
    moveOnMouseWheel: true, // 开启滚轮平移
    moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
  },
]}

export const barChart = ({xData, yData}, overrideOptions, yhint) => {
  const {max} = overrideOptions || {}
  let hint = yhint || '(数量)'
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: 18,
      right: 8,
      bottom: 16,
      top: 48,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLine: {
          show: true,
          lineStyle: {
            type: [5, 5],
            color: '#063374',
            width: 1,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          show: true,
          textStyle: {
            color: '#fff',
          },
          formatter: function (value) {
            return echarts.format.truncateText(value, 80, '…');
          }
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: hint,
        nameTextStyle: {
          color: '#fff',
          align: 'center'
        },
        min: 0,
        max: max || 100,
        axisLabel: {
          formatter: '{value}',
          color: '#fff',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#00c7ff',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#063374',
          },
        },
      },
    ],
    series: [
      {
        type: 'bar',
        data: yData,
        barWidth: 16,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#4AAEFF',
              },
              {
                offset: 1,
                color: '#72E4F3',
              },
            ]),
            opacity: 1,
          },
        },
      },
    ],
    dataZoom: slider(xData.length, 5)
  }
}

export const mutBarChart = ({leArr, xData, yFirst, ySecond, yThird}, overrideOptions) => {
  const {max} = overrideOptions || {}
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: 18,
      right: 8,
      bottom: 16,
      top: 48,
      containLabel: true,
    },
    legend: {
      data: leArr,
      icon: 'rect',
      itemWidth: 14,
      itemHeight: 14,
      right: 5,
      textStyle: {
        fontSize: 12,
        color: '#FFFFFF'
      }
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLine: {
          show: true,
          lineStyle: {
            type: [5, 5],
            color: '#063374',
            width: 1,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          show: true,
          textStyle: {
            color: '#fff'
          },
          formatter: function (value) {
            return echarts.format.truncateText(value, 70, '…');
          }
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '(数量)',
        nameTextStyle: {
          color: '#fff',
          align: 'right',
        },
        min: 0,
        max: max || 1000,
        axisLabel: {
          formatter: '{value}',
          color: '#fff',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#00c7ff',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#063374',
          },
        },
      },
    ],
    series: [
      {
        name : leArr[0],
        type: 'bar',
        data: yFirst,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#5AD7A1',
              },
              {
                offset: 1,
                color: '#D5F79C',
              },
            ]),
            opacity: 1,
          },
        },
        // 调整柱状图之间的间隔
        barCategoryGap: '40%',
      },
      {
        name: leArr[1],
        type: 'bar',
        data: ySecond,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#4AAEFF',
              },
              {
                offset: 1,
                color: '#72E4F3',
              },
            ]),
            opacity: 1,
          },
        },
      },
      {
        name: leArr[2],
        type: 'bar',
        data: yThird,
        itemStyle: {
          normal: {
            color: '#4D92F3',
          },
        },
      },
    ],
    dataZoom: slider(xData.length, 5)
  }
}
export const mutBarChart1 = ({leArr, xData, yFirst, ySecond}, overrideOptions) => {
  const {max} = overrideOptions || {}
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: 18,
      right: 8,
      bottom: 16,
      top: 48,
      containLabel: true,
    },
    legend: {
      data: leArr,
      icon: 'rect',
      itemWidth: 14,
      itemHeight: 14,
      right: 5,
      top: 15,
      textStyle: {
        fontSize: 12,
        color: '#FFFFFF'
      }
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLine: {
          show: true,
          lineStyle: {
            type: [5, 5],
            color: '#063374',
            width: 1,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          show: true,
          textStyle: {
            color: '#fff'
          },
          formatter: function (value) {
            return echarts.format.truncateText(value, 80, '…');
          }
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '(数量)',
        nameTextStyle: {
          color: '#fff',
          align: 'right',
        },
        min: 0,
        max: max || 1000,
        axisLabel: {
          formatter: '{value}',
          color: '#fff',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#00c7ff',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#063374',
          },
        },
      },
    ],
    series: [
      {
        name : leArr[0],
        type: 'bar',
        data: yFirst,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#5AD7A1',
              },
              {
                offset: 1,
                color: '#D5F79C',
              },
            ]),
            opacity: 1,
          },
        },
        // 调整柱状图之间的间隔
        barCategoryGap: '70%',
      },
      {
        name: leArr[1],
        type: 'bar',
        data: ySecond,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#4AAEFF',
              },
              {
                offset: 1,
                color: '#72E4F3',
              },
            ]),
            opacity: 1,
          },
        },
      }
    ],
    dataZoom: slider(xData.length, 5)
  }
}

export const randomByLevel = (options) => {
  const defaultOptions = {min: 10, max: 100, level: 0}
  const mergedOptions = options ? {...defaultOptions, ...options, level: options.level || defaultOptions.level || 0 } : defaultOptions
  return random(mergedOptions.min, mergedOptions.max / (mergedOptions.level + 1))
}

export const AppKey = '2237f3c5adf1f92a831f0b4f0e5b1040'
export const AppSecret = '46f48b1d80b6'
export const getToken2 = () => {

  const Nonce = Date.now()
  const CurTime = ~~(Date.now() / 1000);

  const str = AppSecret + Nonce + CurTime
  const CheckSum = CryptoJS.SHA1(str).toString()

  const params = new URLSearchParams();
  params.append('accid', JSON.parse(sessionStorage.getItem('userInfo')).id.replaceAll('-', ''));

  return axios.post("https://api.netease.im/nimserver/user/create.action", params, {
    headers: {
      "Content-Type": 'application/x-www-form-urlencoded',
      AppKey,
      Nonce,
      CurTime,
      CheckSum,
    },
  }).then(resp => {
    if(resp.data.code === 414 && resp.data.desc.includes('already register')){
      return refreshToken()
    }
    return resp
  })
}

export const refreshToken = () => {
  const Nonce = Date.now()
  const CurTime = ~~(Date.now() / 1000);

  const str = AppSecret + Nonce + CurTime
  const CheckSum = CryptoJS.SHA1(str)

  const params = new URLSearchParams();
  params.append('accid', JSON.parse(sessionStorage.getItem('userInfo')).id.replaceAll('-', ''));

  return axios.post("https://api.netease.im/nimserver/user/refreshToken.action", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      AppKey,
      Nonce,
      CurTime,
      CheckSum,
    },
  })
}

export function getGlobalParams(){
  return {
    regionCode: store.state.mapData.addressInfo.regionCode,
    minPeriod: dayjs(store.state.homePage.timeRange[0]).format('YYYY-MM'),
    maxPeriod: dayjs(store.state.homePage.timeRange[1]).format('YYYY-MM'),
  }
}

export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomScaleByLevel (mapLevel){
  switch (mapLevel) {
    case 0:
      return 1
    case 1:
      return 34
    case 2:
      return 34 * randomBetween(10, 15)
    case 3:
      return 34 * randomBetween(10, 15) * randomBetween(8, 12)
    case 4 :
      return 34 * randomBetween(10, 15) * randomBetween(8, 12) * randomBetween( 5, 12)
    default:
      break;
  }
}